import Dispatcher from '../events.js'
import store from '@/store/index.js'

function OverlayWrapper(map, {buttons=[], options={}, classes=[]} = {}) { 
  this._cmpInstance = null;  

  this.buttons = buttons;
  this.options = options;

  classes.unshift('custom-overlay');
  this._additionalClasses = classes;

  this.dispatcher = new Dispatcher();
  this.setMap(map);
}

// OverlayWrapper.prototype = new google.maps.OverlayView();
OverlayWrapper.prototype = Object.create(google.maps.OverlayView.prototype);

OverlayWrapper.prototype.onAdd = function() {
  let panes = this.getPanes();
  let float = panes.floatPane;

  let container = document.createElement('div');
  container.style.position = 'absolute';
  container.draggable = true;
  
  let ComponentClass = Vue.extend(this.cmp);
  let instance = new ComponentClass({
    propsData: {
      overlay: this,
      buttons: this.buttons,
      options: this.options
    }
  });

  instance.$on('overlay-event', this.handleOverlayEvent.bind(this));

  instance.$mount();
  container.appendChild(instance.$el);
  container.classList.add(...this._additionalClasses);

  float.appendChild(container);

  this._container = container; 
  this._cmpInstance = instance;

  this.buttons.forEach((btn) => {
    btn.behavior.setup.call(this, btn);      
  });

  this.dispatcher.dispatch('add');
}

OverlayWrapper.prototype.handleOverlayEvent = function(eventName, btn, e) { 
  if (this._visible) {
    // console.log('in overlayevent', e);

    let mappedEvent = btn.behavior.handleEvent(eventName, e);
    let data = e;
    
    if (mappedEvent) {      
      if (mappedEvent in btn.behavior) {
        data = btn.behavior[mappedEvent].call(this, btn, e);
      }
      
      if (data !== false) {
        this.dispatcher.dispatch(`${btn.name}-${mappedEvent}`, data);
        // console.log(`eventName: ${eventName} btn: ${btn.name}`);
      }
    }
  }
}

OverlayWrapper.prototype.onRemove = function() {
  if (this._container) {
    this._container.parentNode.removeChild(this._container);
    delete this._container;
  }
}

OverlayWrapper.prototype.draw = function() {
  if (this._container && this.get('position')) {
    let projection = this.getProjection();
    
    
    let {x, y} = projection.fromLatLngToDivPixel(this.get('position'));

    this._container.style.top = y + 'px';
    this._container.style.left = x + 'px';

    this.$setProp('x', x);
    this.$setProp('y', y);
  }  
}

OverlayWrapper.prototype.$setProp = function(key, val) {
  let attempts = 0;
  let maxAttempts = 10;

  const fn = () => {
    if (this._cmpInstance) {
      this._cmpInstance.$props[key] = val;
      return;
    }

    if (attempts < maxAttempts) {
      attempts++;
      setTimeout(fn, 100);
    }
  }

  fn();
}

OverlayWrapper.prototype.setPosition = function(latLng) {
  this.set('position', latLng);  
  let fn = () => this.draw();
  
  if (this._container) return fn();  
}

OverlayWrapper.prototype.setVisible = function(visible) {
  this._visible = ((visible === null || visible)) ? true : false;
  // console.log(visible);
  let fn = () => {    
    this.draw();

    setTimeout(() => this.$setProp('visible', (this._visible)), 100);

    if (this._visible) {
      this._container.style.display = 'block'
      this.set('visible', true);

      if (this._timer) clearTimeout(this._timer);      
    } else {      
      this._timer = setTimeout(() => {
        this.set('visible', false);
        this._container.style.display = 'none';
       }, 400);
    }    
  }

  if (this._container) return fn();
  this.dispatcher.on('add', fn, true);
}

export default OverlayWrapper;