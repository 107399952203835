/* Distance.js */
import { MapShape } from './MapShape';
import { defaultsDeep, has } from 'lodash';

import { startEndHandleZoomFormulas, midPointZoomFormulas } from '../constants';

import styles from './shape_styles/DistanceStyles';
import StyleManager from './shape_styles/StyleManager';

import store from '@/store/index.js'

const styleMgr = new StyleManager(styles);

function checkNearLine(checkX, checkY, x0 , y0 , x1, y1, threshold = 5)
{
	const coords = [];
  const lineLow = (x0, y0, x1, y1) => {
    const dx = x1 - x0
    let dy = y1 - y0
    let yi = 1

    if (dy < 0) {
      yi = -1
      dy = -dy
    }

    let D = 2 * dy - dx
    let y = y0

    for (let x = x0; x < x1; x++) {
    	coords.push({x, y});

      if (D > 0) {
        y = y + yi
        D = D - 2 * dx
      }

      D = D + 2 * dy
    }
  }

  const lineHigh = (x0, y0, x1, y1) => {
    let dx = x1 - x0
    const dy = y1 - y0
    let xi = 1

    if (dx < 0) {
      xi = -1
      dx = -dx
    }

    let D = 2 * dx - dy
    let x = x0

    for (let y = y0; y < y1; y++) {
      coords.push({x, y});

      if (D > 0) {
        x = x + xi
        D = D - 2 * dy
      }

      D = D + 2 * dx
    }
  }

  const { abs } = Math

  if (abs(y1 - y0) < abs(x1 - x0)) {
    if (x0 > x1) {
      lineLow(x1, y1, x0, y0)
    } else {
      lineLow(x0, y0, x1, y1)
    }
  } else {
    if (y0 > y1) {
      lineHigh(x1, y1, x0, y0)
    } else {
      lineHigh(x0, y0, x1, y1)
    }
  }
  
  return (coords.filter(coord => {
  	const xMatch = (checkX >= (coord.x - threshold) && checkX <= (coord.x + threshold));
    const yMatch = (checkY >= (coord.y - threshold) && checkY <= (coord.y + threshold));
    
    return (xMatch && yMatch);
  }).length > 0);
}

function Distance(map, params) {
  defaultsDeep(params, {
    indexID: new Date().getTime(),
    labelText: '',
    highlighted: false,
    distanceVisible: true,
    cutout: false,
    paths: null,
    selected: false,
    selectedHandleIdx: null,  
    mode: null,
    alwaysDisplayHandles: true,    
    styleMgr,
    initialZoom: null
  });

  this._type = 'distance';  
  this._poly = new google.maps.Polyline({
    path: params.paths
  });
  
  this._poly.setOptions(params.styleMgr.get('shape'));  
  MapShape.call(this, map, params);
}

Distance.prototype = Object.create(MapShape.prototype);
Distance.prototype.constructor = Distance;

Distance.prototype.getState = function() {
  let state = {
    type: this.getType(),
    labelText: this.getLabelText(),
    paths: this._poly.getPath().getArray().reduce((accum, val) => {
      accum.push(val.toJSON());
      return accum;
    }, []),
    cutout: this._cutout,
    distanceVisible: this._distanceVisible,
    selected: this._selected,
    highlighted: this._highlighted,    
    indexID: this.indexID,    
    calculated: this.getCalculated(),
    segmentLengths: this.getSegmentLengths(),
    initialZoom: this._initialZoom
  }    
  
  return state;
}

Distance.prototype._handleMapZoomChange = function(newZoom) {
  MapShape.prototype._handleMapZoomChange.call(this, newZoom);
}

Distance.prototype._getHandleAnchor = function({ start=false, end=false }) {
  if (start || end) {
    let anchors = {
      21: [12, 3],
      20: [12, 3],
      19: [12, 3],
      18: [12, 3],
      17: [12, 3]
    }

    return (anchors[this._zoom]) ? new google.maps.Point(...anchors[this._zoom]) : ((this._zoom <= 17) ? new google.maps.Point(...anchors[19]) : new google.maps.Point(...anchors[17]));
  }
  
  return null;
}

Distance.prototype._getHandleZoom = function({ start, end }) {    
  const formulas = (start || end) ? startEndHandleZoomFormulas : midPointZoomFormulas;
  // console.log('initial zoom: ', this._initialZoom, 'new zoom: ', this._zoom, 'difference: ', this._initialZoom - this._zoom);    
  const size = formulas[this._initialZoom](this._zoom);

  return (size < .1) ? .1 : size;
}

Distance.prototype._customGetHandleVisibility = function(idx) {    
  if ((store.state.mode == 'draw' || store.state.mode == 'free') && store.getters.currentShape.indexID != this.indexID) return false;
  if (this._distanceVisible || this._mode == 'draw') {
    return true;
  }
  
  if (idx == 0 || idx == this.getPathVertexCount() - 1) {    
    return true;
  }
  
  return false;
}

Distance.prototype._customGetHandleRotation = function(idx, { start, end } = {}) {  
  const path = (path || this._poly.getPath());
  const vertexCount = this.getPathVertexCount();
  
  const getPrev = () => {
    let fromIdx = idx - 1;
    let toIdx = idx;
    
    if (start) {
      fromIdx = 0;
      toIdx = 1;
    }
  
    return this._getAngle(path.getAt(fromIdx), path.getAt(toIdx)) + ((end) ? 180 : 0); 
  }

  const getNext = () => {
    let fromIdx = idx;
    let toIdx = idx + 1;
    
    if (end) {
      fromIdx = vertexCount - 2;
      toIdx = vertexCount - 1;
    }
  
    return this._getAngle(path.getAt(fromIdx), path.getAt(toIdx)) + ((end) ? 180 : 0); 
  }

  
  const prevAngle = getPrev();
  const nextAngle = getNext();
  
  let finalAngle = (prevAngle != nextAngle) ? (prevAngle + nextAngle) / 2 : prevAngle;
  
  const diff = Math.abs(prevAngle - nextAngle % 360);
  // console.log('angle: ', diff);
  if(!(start || end) && (diff / 360) > .25) {
    finalAngle += 90;
  }

  return finalAngle;
}

Distance.prototype.updateFromState = function(state, force = false) {
  // console.log(state);
  let curState = this.getState();
  
  if (force || (JSON.stringify(curState) != JSON.stringify(state)))  {
    if (force || state.selected != this._selected) this.setSelected(state.selected);
    if (force || state.highlighted != this._highlighted) this.setHighlighted(state.highlighted);
    if (force || state.cutout != this._cutout) this.setCutOut(state.cutout);
    if (force || state.distanceVisible != this._distanceVisible) this.setDistanceVisible(state.distanceVisible);
    if (force || state.labelText != curState.labelText) this.setLabelText(state.labelText);
    if (force || state.calculated != curState.calculated) this.dispatcher.dispatch('updated', this);
    

    this._updatePathFromArray(state.paths);    
  }
}

Distance.prototype.getCalculated = function() {  
  let area = google.maps.geometry.spherical.computeLength(this.getPoly().getPath()); 
  return (this._cutout) ? -Math.abs(area) : Math.abs(area);
}

Distance.prototype.isLatLngClickMatch = function(latLng) {
  let projection = this._map.overlay.getProjection();
  let path = this._poly.getPath();

  let {x: clickX, y: clickY} = projection.fromLatLngToContainerPixel(latLng);
  let match = false;

  path.forEach((pathLatLng, idx) => {
    if (!match) {
      let pair = [pathLatLng, path.getAt(idx + 1)];
      if (pair[1] === undefined) {
        if (this._type == 'distance') {
          return;
        }

        pair[1] = path.getAt(0);
      }

      let {x: startX, y: startY } = projection.fromLatLngToContainerPixel(pair[0]);
      let {x: endX, y: endY } = projection.fromLatLngToContainerPixel(pair[1]);
      // console.log('startx', startX, 'starty', startY)
      // console.log('endx', endX, 'endy', endY)
      // console.log('clickx', clickX, 'clicky', clickY);

      if (checkNearLine(clickX, clickY, startX, startY, endX, endY, 10)) {
        match = true;
      }
    }
  });
  
  return match;
  // return google.maps.geometry.poly.isLocationOnEdge(latLng, this.getPoly(), 10e-6);
}

export {
  Distance
}
