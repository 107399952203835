import OverlayWrapper from './OverlayWrapper'
import ControlOverlayStyles from '../styles/ControlOverlay.css'
import store from '@/store/index.js'

import { v4 as uuidv4 } from 'uuid'
import { defaultsDeep } from 'lodash'

const cmp = {
  template: `
  <nav class="circular-menu">    
    <div v-bind:class="{ 'menu-ct': true, 'circle': options.iconsOnly, 'rectangle': !options.iconsOnly }">
      <template v-for="btn in buttons">
        <a href="javascript:void(0)" :id="getUUID()"
          @touchend.stop.prevent="handleBehavior(btn, 'touchend', $event)" 
          @touchstart.stop.prevent="handleBehavior(btn, 'touchstart', $event)" 
          @touchmove.stop.prevente="handleBehavior(btn, 'touchmove', $event)" 
          @mouseup.stop.prevent="handleBehavior(btn, 'mouseup', $event)" 
          @mousemove.stop.prevent="handleBehavior(btn, 'mousemove', $event)" 
          @mousedown.stop.prevent="handleBehavior(btn, 'mousedown', $event)" 
          @click.stop.prevent="handleBehavior(btn, 'click', $event)" 
        >
            <component v-if="options.iconsOnly" :is="btn.icon" />
            <span v-else>{{ btn.text }}</span>
        </a>
      </template>
    </div>
  </nav>
  `,
  props: {
    'x': Number,
    'y': Number,    
    'buttons': Array,
    'overlay': Object,
    'options': {
      type: Object,
      default: {}
    },
    'visible': {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler: function(visible) {
        if (visible) {
          this.$el.querySelector('.menu-ct').classList.add('open');            
          store.dispatch('setCurrentControlOverlay', { current: this.overlay, uuid: this.uuid, prefix: this.options.prefix });
          // console.log('visible');
          return;
        }
        
        // store.dispatch('setCurrentControlOverlay', { current: null, uuid: '', prefix: '' });
        this.$el.querySelector('.menu-ct').classList.remove('open');      
      }      
    }
  },
  methods: {
    getUUID() {
      return uuidv4();
    },
    handleBehavior(btn, eventName, e) {
      this.$emit(`overlay-event`, eventName, btn, e);
      return false;
    }
  },
  mounted() {    
    let items = this.$el.querySelectorAll('.menu-ct a');
    
    for(var i = 0, l = items.length; i < l; i++) {
      items[i].style.left = (50 - this.options.distance*Math.cos(-0.5 * Math.PI - 2*(1/l)*i*Math.PI)).toFixed(4) + "%";        
      items[i].style.top = (50 + this.options.distance*Math.sin(-0.5 * Math.PI - 2*(1/l)*i*Math.PI)).toFixed(4) + "%";
    }
  },
  data() {
    return {
      uuid: uuidv4()
    }
  },  
  name: 'MarkerControlOverlay'
}

function Overlay(map, config = {}) {
  const defaults = {
    options: {
      distance: 50,
      prefix: '',
      iconsOnly: true
    },
    classes: [],
    buttons: []
  };

  config = defaultsDeep(config, defaults);
  // config = Object.assign(defaults, config);
  if (config.options.prefix) config.options.prefix += '-';
  
  OverlayWrapper.call(this, map, config);
  this.cmp = cmp;  
}

Overlay.prototype = Object.create(OverlayWrapper.prototype);
export default Overlay;