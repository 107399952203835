import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'

export default {
  namespaced: true,
  modules: {
  },
  state: {    
    db: null,
    app: null,
    initing: true,   
    firebaseConfig: {
      apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID
    }
  },
  mutations: {
    SET_APP(state, app) {
      state.app = app;
    },
    SET_DB(state, db) {
      state.db = db;
    },
    SET_INITING(state, initing = true) {
      state.initing = initing;
    }
  },
  actions: {
    async initialize({ state, commit, dispatch }) {
      console.log('init backend');
      if (!state.app) {
        console.log(state.app);
        console.log('initializing firebase');
        
        commit('SET_APP', firebase.initializeApp(state.firebaseConfig));

        if (process.env.VUE_APP_EMULATOR_IP) {
          firebase.functions().useEmulator(process.env.VUE_APP_EMULATOR_IP, 5001);          
        }
        
        commit('SET_DB', firebase.firestore());
        console.log(state.app);
        
        commit('SET_INITING', false);
      }           
    },    
    async geocodeAddress({ dispatch, commit }, address) {      
      commit('SET_LOADING', {
        loading: true
      }, {
        root: true
      });

      try {
        const fn = firebase.functions().httpsCallable('geocodeAddress');
        const result = await fn({ address, test: (process.env.VUE_APP_IP_TEST_MODE == 'true') });
        
        if (!result.data || result.data.status != 200 || !result.data.latLng) {      
          throw new Error('Unable to geocode address: ' + result.data.status);
        }

        return result.data;                
      } catch (e) {
        throw new Error(e.message);
      } finally {
        commit('SET_LOADING', {
          loading: false
        }, {
          root: true
        });
      }
    }    
  },
  getters: {
    isIniting(state) {
      return state.initing;
    }
  }
}