/* area.js */
import { MapShape } from './MapShape';
import store from '@/store/index.js'

import { defaultsDeep, get } from 'lodash';
import { NotificationProgrammatic } from 'buefy';

import styles from './shape_styles/AreaStyles';
import StyleManager from './shape_styles/StyleManager';

const styleMgr = new StyleManager(styles);

function Area(map, params) {  
  defaultsDeep(params, {
    indexID: new Date().getTime(),
    labelText: '',
    highlighted: false,
    cutout: false,
    distanceVisible: false,
    paths: null,
    selected: false,
    selectedHandleIdx: null,
    mode: null,
    initialZoom: null,
    styleMgr
  });

  this._type = 'area';
  this._poly = new google.maps.Polygon({
    paths: params.paths
  });
  
  // this._poly.set('zIndex', store.state.globalZIndex);  
  this._poly.setOptions(params.styleMgr.get('shape'));  
  MapShape.call(this, map, params);  
}

Area.prototype = Object.create(MapShape.prototype);

Area.prototype._initEvents = function() {
  MapShape.prototype._initEvents.call(this);

  this.dispatcher.on('modechanged', this._handleModeChanged.bind(this));
}

Area.prototype._handleModeChanged = function(mode) {
  if (mode == 'move') {
    // if the area contains less than 3 vertices, it can no longer be a polygon. If the user leaves edit mode in this state, delete the area.
    if (this._poly.getPath().length < 3) {
      store.dispatch('deleteShape', { shape: this, skipConfirm: true });
    }   
  }
}

Area.prototype._customGetHandleVisibility = function(idx) {
  if (this._selected && this._mode == 'draw') {
    return true;
  }

  return false;
}

Area.prototype.getState = function() {
  let state = {
    type: this.getType(),
    labelText: this.getLabelText(),
    displayIndex: this.getDisplayIndex(),
    paths: this._poly.getPath().getArray().reduce((accum, val) => {
      accum.push(val.toJSON());
      return accum;
    }, []),
    distanceVisible: this._distanceVisible,
    cutout: this._cutout,
    selected: this._selected,
    highlighted: this._highlighted,    
    indexID: this.indexID,    
    calculated: this.getCalculated(),
    segmentLengths: this.getSegmentLengths(),
    initialZoom: this._initialZoom
  }  

  return state;
}

Area.prototype.updateFromState = function(state, force = false) {  
  let curState = this.getState();

  if (force || (JSON.stringify(curState) != JSON.stringify(state)))  {
    if (force || state.selected != this._selected) this.setSelected(state.selected);
    if (force || state.highlighted != this._highlighted) this.setHighlighted(state.highlighted);
    if (force || state.cutout != this._cutout) this.setCutOut(state.cutout);
    if (force || state.distanceVisible != this._distanceVisible) this.setDistanceVisible(state.distanceVisible);
    if (force || state.labelText != curState.labelText) this.setLabelText(state.labelText);
    if (force || state.displayIndex != curState.displayIndex) this.setDisplayIndex(state.displayIndex);
    if (force || state.calculated != curState.calculated) this.dispatcher.dispatch('updated', this);    
    
    this._updatePathFromArray(state.paths);
  }
}

Area.prototype.isLatLngClickMatch = function(latLng) {
  return google.maps.geometry.poly.containsLocation(latLng, this.getPoly());
}

Area.prototype.getCalculated = function() {  
  let area = google.maps.geometry.spherical.computeArea(this.getPoly().getPath()); 
  return (this._cutout) ? -Math.abs(area) : Math.abs(area);
}

export {
  Area
}