import Vue from 'vue'
window.Vue = require('vue').default;

Vue.config.productionTip = false

import './styles/global-styles-v1.css'
import '@/icons'

import 'buefy/dist/buefy.css'
import './styles/buefy-overrides.css'
import { Navbar, Menu, Sidebar, Snackbar, Modal, Icon, Carousel, Switch, Dialog, Notification, Tabs, Table, Button, Tooltip, Select, Input, Checkbox, Dropdown, Field, Upload, Loading } from 'buefy'

import { findLastIndex, get } from 'lodash'

Vue.use(Navbar);
Vue.use(Menu);
Vue.use(Sidebar);
Vue.use(Snackbar);
Vue.use(Modal);
Vue.use(Icon);
Vue.use(Carousel);
Vue.use(Switch);
Vue.use(Dialog);
Vue.use(Notification);
Vue.use(Tabs);
Vue.use(Table);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Select);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Dropdown);
Vue.use(Field);
Vue.use(Upload);
Vue.use(Loading);

import store from '@/store/index.js'

import VueRouter from 'vue-router'
import App from './App.vue'

import MapApp from './MapApp.vue'

import FlowModal from '@/components/FlowModal.vue'
import ConfirmAddressBanner from '@/components/lead_workflow_components/ConfirmAddressBanner.vue'
import OutlineAreasBanner from '@/components/lead_workflow_components/OutlineAreasBanner.vue'
import MapResultsBanner from '@/components/lead_workflow_components/MapResultsBanner.vue'
import MapEditBanner from '@/components/lead_workflow_components/MapEditBanner.vue'

import ConfigMixin from '@/mixins/ConfigMixin.js'

Vue.use(VueRouter);
Vue.mixin(ConfigMixin);

const router = new VueRouter({
  mode: 'history'
});

router.addRoutes([{
  path: '/', 
  redirect: '/flow/introduction',
  name: 'Introduction'
},{
  name: 'OutlineAreas',
  path: '/flow/outline-areas',
  meta: {
    viewMode: 'edit',
    showControlPanel: true,
    showStatusPanel: true,
  },
  components: {
    default: MapApp,
    toolbar: OutlineAreasBanner
  }
},{
  name: 'ConfirmAddress',
  path: '/flow/confirm-address',
  components: {
    default: MapApp,
    toolbar: ConfirmAddressBanner
  }
},{
  name: 'Flow',
  path: '/flow/:stepName',
  components: {
    default: MapApp,
    dialog: FlowModal
  },
  props: {
    dialog: { 'first-step-name': 'Introduction'}
  }
},{
  name: 'MapResults',
  path: '/map/:mapId/results',
  meta: {
    viewMode: 'view',
    showMeasurements: true,
    showStatusPanel: true,
    showSharingDetails: true
  },
  components: {
    default: MapApp,
    toolbar: MapResultsBanner
  }
},{
  name: 'MapResultsEdit',
  path: '/map/:mapId/edit',
  meta: {
    viewMode: 'edit',
    showControlPanel: true,
    showControlPanelSearchButton: false,
    showMeasurements: true,
    showStatusPanel: true,
    showSharingDetails: true,    
  },
  components: {
    default: MapApp,
    toolbar: MapEditBanner
  }
},{
  name: 'MapScreenshot',
  path: '/map/:mapId/screenshot',
  meta: {
    viewMode: 'view',
    showMeasurements: true,
    showStatusPanel: false,
    showSharingDetails: false
  },
  components: {
    default: MapApp,
    toolbar: MapResultsBanner
  }
}]); 

router.beforeEach((to, from, next) => {  
  store.dispatch('initializeBackend');
  next();
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
