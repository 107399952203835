<style>
div#side-pane {
  padding: .5em;
  position: absolute;
  top: 30%;
  right: 0;
  border: none;
  background-color: #fff;
  border-radius: 5px 0 0 5px;
}

a.button.is-active img.custom-icon {
  filter:  brightness(0) invert(1);
}

div.field.avatar-ct {
  display: flex;
  flex-direction: row;
}

div.field.avatar-ct div.dropdown {
  justify-content: center;
  flex-grow: 1;
}

/* div#controls a.button {
  background-color: #000;
} */
</style>

<template>
  <div id="side-pane">
    <div id="controls" v-bind:class="{hidden: !visible}">
      <div v-if="showSearchButton" v-show="!currentShape.indexID && mode != 'free'" class="field">
        <a class="button" @click="searchButtonClick" title="Search for a new address">
          <span class="icon">
            <magnify-icon />
          </span>
        </a>
      </div>
      <div class="field" v-show="mode == 'move' && currentShape.indexID">
        <a class="button" @click="clearCurrentShape()" title="Done with this layer">
          <span class="icon">
            <check-icon />
          </span>
        </a>
      </div>
      <div class="field" v-show="mode == 'draw' && currentShape.paths && (currentShape.type == 'area' && currentShape.paths.length > 2 || currentShape.type == 'distance' && currentShape.paths.length > 1)">
        <a class="button" @click="setMode('move')" title="Done changing points">
          <span class="icon">
            <check-icon />
          </span>
        </a>
      </div>
      <div class="field" v-show="mode == 'move'">
        <b-dropdown position="is-bottom-left" >
          <a @click.prevent slot="trigger" class="button" title="Create a new measurement"> 
            <span class="icon"><tape-measure-icon /></span>
          </a>
          <b-dropdown-item @click="addLayer('area','free')" :class="{'is-primary is-active': mode == 'free'}">
            <span class="icon">
              <draw-icon />
            </span>
            <span>Area (free draw)</span>
          </b-dropdown-item>
          <b-dropdown-item @click="addLayer('area','rectangle')">
            <span class="icon">
              <shape-rectangle-plus-icon />
            </span>
            <span>Area (rectangle)</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="field" v-show="mode == 'free' || (currentShape.paths && (currentShape.type == 'area' && currentShape.paths.length < 3 || currentShape.type == 'distance' && currentShape.paths.length < 2))">
        <a class="button" @click="cancelFreeDraw" title="Cancel drawing">
          <span class="icon">
            <close-circle-icon />
          </span>
        </a>
      </div>
      <div v-show="mode == 'move'" class="field">
        <a class="button" @click="showTutorial" title="Helpful tips">
          <span class="icon">
            <help-icon />
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  window.Vue = require('vue').default;
  import Vuex from 'vuex';  

  import { NEW_AREA } from '../constants';
  import TapeMeasure from 'vue-material-design-icons/TapeMeasure.vue';

  export default {    
    components: {
      TapeMeasure
    },
    props: { 
      minimized: {
        type: Boolean,
        default: false
      },
      showSearchButton: {
        type: Boolean,
        default: true
      }
    },
    data: function() {
      return {
        inputAddress: '',
        visible: true
      }
    },
    computed: {
      ...Vuex.mapGetters({
        'currentShape': 'currentShape',
        'currentUser': 'backend/user/current'
      }),
      ...Vuex.mapState(['address','mode'])
    },
    methods: {
      ...Vuex.mapActions(['setTutorialModalVisibility']),
      manageAccount() {
        this.$router.replace({ name: 'Account' });
      },
      test() {
        this.$store.dispatch('backend/user/migrate', {}, { root: true });
      },
      async logout() {
        await this.$store.dispatch('backend/user/logout');

        this.$store.dispatch('dialogs/snackbar', { message: 'Signed out' });
      },
      saveButtonClick() {
        if (this.currentUser) {
          return this.$store.dispatch('setSaveModalVisibility', true);
        }

        this.$store.dispatch('dialogs/snackbar', { message: 'Saving requires a free account.'});
        this.$router.replace('/account/signin');
      },
      searchButtonClick() {
        this.$store.dispatch('dialogs/confirm', { 
            priority: 0, 
            message: `Searching for a new address will remove all measurement layers from the map. <br><br><span class="has-text-weight-bold">Are you sure?</span>`,
            config: {
              title: 'Warning',
              confirmText: 'Yes, search for a new address',
              cancelText: 'Cancel',
              type: 'is-primary',
              onConfirm: async () => {                       
                await this.$store.dispatch('initNewMap');
                this.$router.replace('/flow/address-search');
              }
            }
          });
      },
      toggleControlVisibility(visible) {
        // console.log('visible: ' + visible);
        this.visible = (visible === null || visible) ? true : false;
      },
      deleteCurrentShape () {
        this.$store.dispatch('deleteShape', { shape: this.currentShape });
      },
      clearCurrentShape () {
        this.$store.dispatch('setCurrentShape', null);
      },
      addLayer (type = 'area', shape = 'free') {        
        this.$emit('add-shape-click', new NEW_AREA({
          type,
          shape
        }));
      },
      cancelFreeDraw() {
        this.deleteCurrentShape();        
      },
      showTutorial() {
        this.setTutorialModalVisibility(true);
      },
      newMap () {
        this.$store.dispatch('dialogs/confirm', { 
          priority: 2, 
          message: 'This will remove all measurement layers from the map. <br><br><span class="has-text-weight-bold">Are you sure?</span>',
          config: {
            title: 'Start a new map?',
            confirmText: 'Continue',
            type: 'is-danger',            
            onConfirm: () => {
              this.$store.dispatch('initNewMap').
                then(() => this.$store.dispatch('setSearchModalVisibility', true));
            }
          }
        }, { root: true });
      },
      ...Vuex.mapActions(['setMode']),
    }
  }
</script>
