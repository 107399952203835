<template>
  <div></div>
</template>

<script>
import Vuex from 'vuex';
import { omit } from 'lodash';

export default {  
  data: function() {
    return {}
  },
  computed: {
    ...Vuex.mapGetters({
      'queue': 'dialogs/queue',
      'active': 'dialogs/active'
    })
  },
  watch: {
    queue(queueItem) {
      if (queueItem) {
        console.log('config', queueItem.message);      
        console.log('active', this.active); 
        if (this.active && this.active.priority < queueItem.priority) {
          this.active.inst.close();
        }
        
        const config = omit(queueItem, ['__type']);          
        
        if (config.onConfirm) config.onConfirm = config.onConfirm.bind(this);
        if (config.onCancel) config.onCancel = config.onCancel.bind(this);

        const type = queueItem['__type'];        
        const inst = (type == 'snackbar') ? this.$buefy.snackbar.open(config) : this.$buefy.dialog[queueItem['__type']](config);          
        if (type !== 'snackbar') {
          // only set dialogs as active
          this.$store.dispatch('dialogs/setActive', { active: { inst, priority: queueItem.priority, uuid: queueItem.uuid }});          
        } else {
          // immediately remove the snackbar from the queue
          config.onConfirm();
        }
      }
    }
  }  
}
</script>
