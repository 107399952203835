/* status-panel.js */

<style>
section.section.status-panel {
  position: relative;
  /* margin-top: 1em !important; */
}

section.status-panel div.hidden {
  display: none;
}

section.status-panel > div {
  padding: .3em;
}

section.status-panel ul {
  margin: .2em 0 1em;
}

section.status-panel ul li {
  padding: .2em .4em;
  border: 1px solid transparent;
  border-radius: 5px;
}

section.status-panel ul.area-list .status-controls a {
  padding-bottom: 3px;
}

section.status-panel ul li.selected {
  border-color: #797070;
  /* box-shadow: 2px 2px 20px rgb(0,0,255,.5); */
}

div.b-tabs section.tab-content {
  padding: 0;
}

div.b-tabs nav.tabs ul {
  margin-top: 0;
  margin-bottom: 0;
}

div.tab-item {
  position: relative;
}

section.status-panel ul.area-list {
  display: block;
  max-height: 6.5em;
  margin-bottom: .2em;
  overflow: auto;

  box-shadow: 
    inset 0px 11px 8px -10px #CCC,
    inset 0px -11px 8px -10px #CCC; 

}

div.level.control-bar {
  margin: 0 0 .5em;
}

div.save-status {
  text-align: center;
  border-radius: 5px;
  background-color: #fff;  
  position: absolute;
  top: -4em;
  
  width: 12em;
  left: 50%;
  transform: translateX(-50%);
}

div.save-status {
  font-size: .8em;
  line-height: .8em;
}

div.save-status span > span {
  font-size: .8em;
}

div.total-area {
  margin-right: .5em;
}

div.total-area span {
  font-size: 1.1em;
}
</style>

<template>
  <section class="section status-panel is-marginless is-paddingless" ref="statusPanel" v-bind:style="statusPanelStyles">
    <div class="box has-background-light">
      <div>
        <ul class="area-list" v-if="areas.length">
          <status-panel-item 
            v-for="area in areas"
            v-bind:key="area.indexID" 
            :selected="(area.indexID == currentShape.indexID)"  
            :value="area.convertedCalculated"
            :display-index="area.displayIndex"
            :distance-visible="area.distanceVisible"
            :view-mode="viewMode"
            :measure-suffix="measureSuffix"
            :label-text="area.labelText"
            :enable-cutout="true"
            :cutout="area.cutout"
            :show-measurement="showMeasurement"                
            @mouseover.native="hoverShape(area)" 
            @mouseup.native="hoverOffShape" 
            @mouseout.native="hoverOffShape" 
            @select="selectShape(area)"
            @delete="deleteShape(area)"
            @label-change="setShapeLabelText($event, area)"
            @distance-visible-change="toggleShapeDistanceVisible($event, area)"                
            @cutout-change="toggleShapeCutout($event, area)"                
            />             
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  import { upperFirst } from 'lodash'

  import Vue from 'vue'
  import Vuex from 'vuex'  
    import StatusPanelItem from './StatusPanelItem.vue';

  export default {
    components: {
      StatusPanelItem
    },
    props: {
      showMeasurement: {
        type: Boolean,
        default: false
      },
      viewMode: {
        type: String,
        default: 'view'
      }
    },
    data: function() {              
      return {
        currentTab: 0,
        height: 0,
        minimized: true,
      }
    },
    computed: {
      ...Vuex.mapGetters(['totalShapeCalculatedConverted','currentShape','imperialStatus','measureSuffix','shapes','currentSlotMeta']),
      ...Vuex.mapGetters({
        'currentUser': 'backend/user/current'
      }),
      ...Vuex.mapState(['currentSlotName','activeStatusTab']),
      activeStatusTabName() {
        return upperFirst(this.$store.getters['activeStatusTabName']);
      },
      areas() {
        return this.shapes.filter(s => s.type == 'area');
      }, 
      distances() {
        return this.shapes.filter(s => s.type == 'distance');
      },
      hide() {
        return this.$store.state.mapDrag || this.$store.state.toolbarsHidden;
      },
      statusPanelStyles() {
        return {
          display: (!this.$store.state.toolbarsHidden) ? 'block': 'none'
        };
      },
    },
    watch: {
      shapes: {
        handler(newShapes, oldShapes) {
          oldShapes = oldShapes || [];
          if (this.$refs.statusPanel) {
            setTimeout(() => {
              this.height = this.$refs.statusPanel.offsetHeight;                            
            }, 500);
          }
        },
        immediate: true      
      },
      activeStatusTab(newTab, oldTab) {
        if (newTab == null) return;
        this.currentTab = newTab;
      },
      currentTab(tab) {        
        console.log('current tab change');
        this.$store.dispatch('setActiveStatusTab', { tab });
      },
      currentShape(newShape, oldShape) {
        if (oldShape && newShape.indexID !== oldShape.indexID) {
          if (newShape.indexID) {
            this.$store.dispatch('setActiveStatusTab', { tab: (newShape.indexID && newShape.type == 'area') ? 0 : 1 });
          }
          
          Vue.nextTick(() => {
            let selected = this.$el.querySelector('ul.area-list li.selected');
            if (selected) selected.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest'
            });
          });
        }
      }
    },
    methods: {
      showAccountSignin() {
        this.$store.dispatch('dialogs/snackbar', { message: 'Saving requires a free account.'});
        this.$router.replace('/account/signin');
      },
      showUnitModal() {
        this.$store.dispatch('setUnitModalVisibility', true);
      },
      showSaveModal() {
        this.$store.dispatch('setSaveModalVisibility', true);
      },
      toggleMinimized(minimized) {
        minimized = ((minimized === undefined) ? ((this.minimized) ? false : true) : minimized);
        this.minimized = (minimized) ? true : false;
        console.log(minimized);
        if (this.minimized) {
          this.$emit('minimized');
          return;
        }

        this.$emit('maximized');
      },
      selectShape(shape) {
        this.$store.dispatch('setCurrentShape', shape);
      },
      deleteShape(shape) {
        this.$store.dispatch('deleteShape', { shape });
      },
      setShapeLabelText($event, shape) {
        this.$store.dispatch('setShapeLabelText', {
          shape,
          labelText: $event
        });
      },
      toggleShapeCutout(cutout, shape) {
        this.$store.dispatch('setShapeCutout', {
          shape,
          cutout
        });
      },
      toggleShapeDistanceVisible(visible, shape) {              
        this.$store.dispatch('setShapeDistanceVisible', {
          shape,
          visible
        }).then(() => {
          if (shape.type == 'area' && visible == true) {
            this.$store.dispatch('dialogs/alert', {
              priority: 0,            
              message: 'Showing the edge lengths of an area <b>does not</b> affect the <i>Total Distance</i> calculation. Use the <i>Measure Distance</i> tool to utilize <i>Total Distance</i>.',
              config: { title: 'Important', type: 'is-warning' }
            });
          }
        });
      },
      toggleMetric(status) {
        this.$store.dispatch('setImperialState', !status);
      },
      hoverShape(shape) {
        this.$store.dispatch('hoverShape', shape);
      },
      hoverOffShape() {
        this.$store.dispatch('hoverShape', null);
      },
      addLayer () {
        this.$intro().exit();
        this.$emit('add-shape-click');
      }
    },
    mounted() {
      this.toggleMinimized(this.minimized);

    }
  }
</script>