function ClickableBehavior() {  
  this.events = {
    'click': 'click',
    'touchend': 'click',
    'touchstart': 'touchstart'
  }
}

ClickableBehavior.prototype.touchstart = function(btn, e) {
  this.getMap().set('_noclicks', true);
  return false;
}

ClickableBehavior.prototype.click = function(btn, e) {
  this.getMap().set('_noclicks', false);
  return true;
}

ClickableBehavior.prototype.setup = function(parent) {
  this.parent = parent;
  // parent.$on('button-click', (e, btn) => parent.overlay.dispatcher.dispatch(`${btn.name}-click`));
  // parent.$on('button-click', (e, btn) => parent.overlay.dispatcher.dispatch(`${btn.name}-click`));
}

ClickableBehavior.prototype.handleEvent = function(eventName, e) {
  if (this.events[eventName]) {     
    return this.events[eventName];
  }
}

export default ClickableBehavior;