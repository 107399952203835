export default {
  normal: {
    handles: {
      draggable: true,  
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeColor: '#000'
      }
    },
    startHandles: {
      draggable: true,  
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeColor: '#000'
      }
    },
    endHandles: {
      draggable: true,  
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeColor: '#000'
      }
    },
    centerMarkers: {
      icon: {
        url: edgeAddCoordIcon,
        anchor: new google.maps.Point(10, 10),
        scaledSize: new google.maps.Size(20, 20)
      }
    },
    shape: {
      strokeColor: '#000',
      // draggable: true,
      clickable: false,
      marker: null,
      geodesic: true,
      strokeWeight: 2,
      fillOpacity: .4,
      fillColor: '#f7f709',
      editable: false    
    }    
  },
  selected: {
    handles: {
      draggable: true,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeColor: 'yellow'
      }
    },
    startHandles: {
      draggable: true,  
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeColor: 'yellow'
      }
    },
    endHandles: {
      draggable: true,  
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeColor: 'yellow'
      }
    },
    shape: {      
      strokeWeight: 3,
      strokeColor: '#fff',
      fillColor: '#f7f709',
      fillOpacity: .2
    }
  },
  highlighted: {
    shape: {
      strokeWeight: 5
    }
  },
  cutout: {
    shape: {
      strokeColor: '#D20011',
      fillColor: '#D20011',
      fillOpacity: .4
    }
  }
}