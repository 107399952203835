<template>
  <li :class="['status-item', { 'selected': selected }]">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item index-display">
          <a href="javascript:void(0);" @click="$emit('select')">
            <span class="display-index">{{ displayIndex }}</span>                            
            <template v-if="viewMode == 'view'">              
              <span v-if="showMeasurement">{{ value }} <span v-html="measureSuffix"></span></span>       
              <span class="area-label">{{ labelText }}</span>
            </template>
          </a>
        </div>
        <div class="level-item" v-if="viewMode == 'edit'">          
          <span v-if="showMeasurement">{{ value }} <span v-html="measureSuffix"></span></span>       
          <span class="area-label">
            {{ labelText }}
          </span>
          <a @click="showAreaLabelPrompt" title="Edit this label" class="level-item"><pencil-icon class="icon-2x" /></a>
        </div>
      </div>
      <div class="level-right status-controls" v-if="viewMode == 'edit'">
        <div class="level-item">
          <b-switch v-if="enableCutout" class="level-item" :value="cutout" @input="$emit('cutout-change', $event)" size="is-small">Subtract</b-switch>                      
          <a @click="$emit('delete')" title="Delete this measurement" class="level-item"><delete-icon class="icon-2x" /></a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import Vue from 'vue'
  import Vuex from 'vuex'  
  import { snackbarDefaults } from '../constants';
  import TooltipWrapper from '@/components/TooltipWrapper.vue'
  
  export default {
    components: {
      TooltipWrapper
    },
    props: {
      selected: {
        type: Boolean,
        default: false
      },
      value: {
        type: Number,
        default: 0
      },
      measureSuffix: {
        type: String,
        default: ''
      },
      enableFillColor: {
        type: Boolean,
        default: false
      },
      fillColor: {
        type: String
      },
      enableCutout: {
        type: Boolean,
        default: false
      },
      cutout: {
        type: Boolean,
        default: false
      },
      distanceVisible: {
        type: Boolean,
        default: false,
      },
      labelText: {
        type: String,
        default: ''
      },
      displayIndex: {
        type: Number,
        default: 0
      },
      showMeasurement: {
        type: Boolean,
        default: false
      },
      viewMode: {
        type: String,
        default: 'edit'
      }
    },
    methods: {
      showAreaLabelPrompt() {
        this.$store.dispatch('dialogs/prompt', {
          priority: 0,
          config: {
            title: 'Set Area Label',
            type: 'is-success',
            message: 'Enter a label for the shape. This will help identify the measurement in your results.',
            inputAttrs: {
              placeholder: 'Enter area label',
              type: 'text',
              trapFocus: true
            },
            confirmText: 'Set Label',
            onConfirm: (value) => {              
              this.$emit('label-change', value);
            }
          }
        });
        
      }
    } 
  }
</script>

<style scoped>
div.index-display {
  margin-right: .1em !important;
}
span.display-index {
  display: inline-block;
  color: #fff;
  font-size: 15px;
  background-color: #000; /* Change this to your desired color */
  border: 1px solid #fff;
  border-radius: 50%; /* This will make it a circle */
  text-align: center;
  line-height: 25px; /* Adjust this based on the size of your circle */
  width: 25px; /* Adjust this based on the size of your circle */
  height: 25px; /* Adjust this based on the size of your circle */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* This will give it a drop shadow */   
  margin-right: 10px;
}

span.area-label {
  font-weight: bold;
  margin: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>