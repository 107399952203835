<template>
  <span class="tooltip-ct" 
    v-observe-visibility="{
      callback: this.changeVisibleState,
      once: false
    }">
      <span class="trigger">
        <slot></slot>
      </span>
      <div class="content">
        <slot name="tooltip-content"></slot>
      </div>
  </span>
</template>

<style scoped>
</style>

<script>
import Vuex from 'vuex'

import store from '@/store/index.js'
import { v4 as uuidv4 } from 'uuid'

import tippy, { followCursor } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css';

export default {
  props: {
    name,
    delay: {
      default: 500
    },
    triggerWhenVisible: {
      default: false,
      type: Boolean
    },
    placement: {
      default: 'left'
    },
    label: {
      default: null
    },
    theme: {
      default: 'light-border'
    },
    justOnce: {
      default: true,
      type: Boolean
    },
    trigger: {
      default: 'mouseenter focus',
      type: String
    },
    hideOnClick: {
      default: false      
    },
    touch: {
      default: () => ['hold', 500]
    },
    triggerTargetClass: {
      default: null,
      type: String
    }
  },
  watch: {
    active(v) {           
      this.instance[(v) ? 'show' : 'hide']();
    },
    appClicked(v, oldV) {      
      if (v !== oldV && v) {        
        this.changeVisibleState(false);
      }
    }
  },
  computed: {
    appClicked() {
      return store.state.appClicked;
    },
    uniqueName() {
      return `${uuidv4()}-${this.name}`;
    },
    tooltips() {
      return store.state.tooltips;
    },    
    active() {      
      return (this.tooltips.hasOwnProperty(this.uniqueName)) ? this.tooltips[this.uniqueName] : false;
    }
  },  
  methods: {    
    changeVisibleState(visible, el) {      
      if (this.triggerWhenVisible && visible) {
        if ((this.justOnce && !this.displayedWhenVisible) || !this.justOnce) {
          setTimeout(() => {            
            this.setTooltipVisibility({
              uniqueName: this.uniqueName, 
              visible,
              useTimeout: true
            });            
            this.displayedWhenVisible = true;
          }, 500);          
        }
        return;
      }

      this.setTooltipVisibility({
        uniqueName: this.uniqueName,
        visible: false
      });
    },
    setTooltipVisibility(payload) {
      store.dispatch('setTooltipVisibility', payload);
    }    
  },
  data() {
    return {
      instance: null,      
      displayedWhenVisible: false        
    }
  },
  mounted() {        
    const triggerTargets = [this.$el.querySelector('.trigger')];
    if (this.triggerTargetClass) triggerTargets.push(this.$el.querySelector('.' + this.triggerTargetClass));

    this.instance = tippy(this.$slots.default[0].elm, {      
      content: this.label || this.$el.querySelector('div.content'),
      allowHTML: true,
      appendTo: document.body,
      placement: this.placement,
      delay: this.delay,
      theme: this.theme,
      trigger: this.trigger,
      triggerTarget: triggerTargets,
      interactive: true,
      touch: this.touch,
      hideOnClick: this.hideOnClick
    });
  }
}
</script>