export default {
  normal: {
    handles: {
      draggable: false,    
      icon: {
        // path: google.maps.SymbolPath.CIRCLE,
        path: 'M1,1,L6,1Z',
        anchor: new google.maps.Point(3, 1),
        scale: 5,
        strokeColor: '#000'
      }
    },  
    startHandles: {
      clickable: false,
      draggable: false,
      icon: {
        path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',    
        anchor: new google.maps.Point(12, 20),
        scale: 1,    
        fillOpacity: 1,
        fillColor: '#C8DF52',
        strokeColor: '#C8DF52'
      }
    },
    endHandles: {
      clickable: false,
      draggable: false,      
      icon: {
        path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',
        anchor: new google.maps.Point(12, 20),
        scale: 1,    
        fillOpacity: 1,
        fillColor: '#C8DF52',
        strokeColor: '#C8DF52'
      }
    },
    centerMarkers: {
      icon: {
        url: edgeAddCoordIcon,
        anchor: new google.maps.Point(10, 10),
        scaledSize: new google.maps.Size(20, 20)
      }
    },
    shape: {
      strokeColor: '#C8DF52',
      // draggable: true,
      clickable: false,  
      geodesic: true,
      strokeWeight: 3,
      fillColor: '#C8DF52 ',
      editable: false
    }    
  },
  selected: {
    handles: {
      draggable: true,
      clickable: true,
      cursor: 'pointer',
      icon: {
        // path: google.maps.SymbolPath.CIRCLE,
        path: 'M1,1,L6,1Z',
        anchor: new google.maps.Point(3, 1),
        scale: 5,
        fillColor: '#fff',
        strokeColor: '#fff'
      }
    },
    startHandles: {
      draggable: true,
      clickable: true,
      cursor: 'pointer',
      icon: {
        path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',            
        scale: 1,    
        fillOpacity: 1,
        fillColor: '#fff',
        strokeColor: '#fff',
        anchor: new google.maps.Point(12, 20)
      }
    },
    endHandles: {
      clickable: true,
      draggable: true,
      cursor: 'pointer',
      icon: {
        path: 'M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z',
        anchor: new google.maps.Point(12, 20),
        scale: 1,    
        fillOpacity: 1,
        fillColor: '#fff',
        strokeColor: '#fff'        
      }
    },
    shape: {      
      strokeWeight: 5,
      strokeColor: '#C8DF52',
      fillColor: '#C8DF52'
    }
  },
  highlighted: {
    centerMarkers: {
      icon: {
        scaledSize: new google.maps.Size(20, 20),
        anchor: new google.maps.Point(10, 10),
        url: edgeAddCoordIcon
      }
    },
    shape: {      
      strokeWeight: 5
    }
  }
}