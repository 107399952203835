import OverlayWrapper from './OverlayWrapper'
import ShapeLabelOverlayStyles from '../styles/ShapeLabelOverlay.css'

import { v4 as uuidv4 } from 'uuid'
import { defaultsDeep } from 'lodash'

const cmp = {
  template: `
  <span class="shape-label">{{text}}</span>
  `,  
  props: {
    'x': Number,
    'y': Number,    
    'overlay': Object,
    'options': {
      type: Object,
      default: {
        
      }
    },
    'text': {
      type: String,
      default: ''
    },
    'visible': {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler: function(visible) {
        if (visible) {
          return;
        }        
      }      
    }
  },
  methods: {
    getUUID() {
      return uuidv4();
    },
    handleBehavior(btn, eventName, e) {
      this.$emit(`overlay-event`, eventName, btn, e);
      return false;
    }
  },
  mounted() {    
    
  },
  data() {
    return {
      uuid: uuidv4()
    }
  },  
  name: 'TextOverlay'
}

function Overlay(map, config = {}) {
  const defaults = {
    options: {
      prefix: '',
      iconsOnly: true,
      text: 'hello world'
    },
    classes: [],
  };

  config = defaultsDeep(config, defaults);
  // config = Object.assign(defaults, config);
  if (config.options.prefix) config.options.prefix += '-';
  
  OverlayWrapper.call(this, map, config);
  this.cmp = cmp;  
}

Overlay.prototype = Object.create(OverlayWrapper.prototype);
export default Overlay;