/* events.js */

function DispatcherEvent(eventName) {
  this.eventName = eventName;
  this.callbacks = [];
}

DispatcherEvent.prototype.registerCallback = function(callback, once = false) {
  this.callbacks.push({
    fn: callback,
    once: once
  });
}

DispatcherEvent.prototype.unRegisterCallback = function(callback) {
  // Get the index of the callback in the callbacks array
  const index = this.callbacks.findIndex(def => def.fn == callback);
  // If the callback is in the array then remove it
  if (index > -1) {
    this.callbacks.splice(index, 1);
    return true;
  }

  return false;
}

DispatcherEvent.prototype.fire = function(data) {
  const callbacks = this.callbacks.slice(0);
  // loop through the callbacks and call each one
  callbacks.forEach((def) => {
      def.fn(data);

      if (def.once) {
        this.unRegisterCallback(def.fn);        
      }
  });
}

DispatcherEvent.prototype.clearCallbacks = function() {
  this.callbacks = [];
}

function Dispatcher() {
  this.events = {};
}

Dispatcher.prototype.dispatch = function(eventName, data) {
  const event = this.events[eventName];

  if (event) {
    event.fire(data);
  }
}

Dispatcher.prototype.on = function(eventName, callback, once = false) {
  let event = this.events[eventName];

  if (!event) {
      event = new DispatcherEvent(eventName);
      this.events[eventName] = event;
  }

  event.registerCallback(callback, once);
}

Dispatcher.prototype.off = function(eventName, callback) {
  // First get the correct event
  const event = this.events[eventName];
  // Check that the event exists and it we successfully unregistered the callback
  if (event && ((callback && event.unregisterCallback(callback)) || (!callback && event.clearCallbacks()))) {
    // if the event has no callbacks left, delete the event
    if (event.callbacks.length === 0) {
      delete this.events[eventName];
    }
  }
}

export default Dispatcher;