/* getters.js */

import { get, isFunction, cloneDeep } from 'lodash'
import { sqMetersInSqFt } from '@/constants.js'

import moment from 'moment'
import convert from 'convert-units'

import { units, default_units, base_units } from '@/app-config'

function roundTo(number, places) {
  return +(Math.round(number + "e+" + places)  + "e-" + places);
}

export default {
  shapes: (state, getters) => {
    return state.shapes.map(s => {
      // console.log('default unit: ', getters.currentDefaultUnit.name);
      // if type is not set on shape, it's likely this was a pre-distance supporting state, assume area
      const sType = get(s, 'type', 'area');
      const unit = state.primaryUnits[sType] || units.find(u => u.name == default_units[sType]);
      
      const baseDistanceUnit = base_units['distance'];
      const distanceUnit = state.primaryUnits['distance'] || units.find(u => u.name == default_units['distance']);

      const baseUnitName = base_units[sType];      
      
      return {        
        ...s,
        convertedCalculated: roundTo(convert(s.calculated).from(baseUnitName).to(unit.name), unit.decimals),
        // convertedSegmentLengths: [],
        // convertedSegmentLengths: s.segmentLengths,
        convertedSegmentLengths: s.segmentLengths.map(l => roundTo(convert(l).from(baseDistanceUnit).to(distanceUnit.name), distanceUnit.decimals)),
        distanceSuffix: distanceUnit.suffix,
        suffix: unit.suffix
      }
    });  
  },
  measureSuffix: (state, getters) => getters.primaryUnit.suffix,
  imperialStatus: state => state.imperial ? true : false,  
  lastMapClickLatLng: state => {
    if (state.lastMapClickEvent) {
      let { lat, lng } = state.lastMapClickEvent.latLng;
      
      if (isFunction(lat)) {
        lat = lat(); 
        lng = lng();      
      } 


      return new google.maps.LatLng(lat, lng);
    }

    return false;
  },
  largestShape: state => {
    return state.shapes.reduce((largestShape, shape) => {
      if (shape.calculated > largestShape.calculated) {
        return shape;
      }

      return largestShape;
    }, state.shapes.length ? state.shapes[0] : { calculated: 0 });
  },
  currentShape: state => {
    let shape = state.shapes.find(s => s.selected);
    return (shape) ? shape : { indexID: null };
  },
  hoveredShape: state => {
    // return state.areas.find(a => a.getHighlightedStatus())
  },
  filteredUnits: (state, getters) => {    
    console.log('activestatustabname: ', getters.activeStatusTabName);
    return units.filter(u => u.type == getters.activeStatusTabName && u.group == ((getters.imperialStatus == true) ? 'imperial' : 'metric'));
  },
  primaryUnit: (state, getters) => {    
    const activeTab = getters.activeStatusTabName;
    if (!state.primaryUnits[activeTab] || !units.find(u => u.name == state.primaryUnits[activeTab].name)) {
      return units.find(u => u.name == getters.currentDefaultUnit.name);
    }

    return state.primaryUnits[activeTab];
  },  
  currentDefaultUnit: (state, getters) => {
    return units.find(u => u.name == default_units[getters.activeStatusTabName] && u.type == getters.activeStatusTabName);
  },
  currentBaseUnit: (state, getters) => {
    return units.find(u => u.name == base_units[getters.activeStatusTabName] && u.type == getters.activeStatusTabName);
  },
  totalShapeCalculatedConverted: (state, getters) => {
    let rawCalculated = getters.totalShapeCalculatedRaw;   
    let converted = roundTo(convert(rawCalculated).from(getters.currentBaseUnit.name).to(getters.primaryUnit.name), getters.primaryUnit.decimals);
    return converted;
  },
  totalShapeCalculatedRaw: (state, getters) => {    
    let shape = state.shapes.reduce((accum, current) => {
      if (current.type == getters.activeStatusTabName) {        
        return accum + current.calculated;
      }

      return accum;
    }, 0);

    return shape;
  },
  activeStatusTabName: state => {        
    return (state.activeStatusTab == 0 || state.activeStatusTab == null) ? 'area' : 'distance';
  },
  currentSlotMeta: (state, getters) => {      
    let meta = get(getters['backend/storage/saveSlots'], `${state.currentSlotName}].meta`, {
      saved: null,
      name: ''
    });
    
    return Object.assign(meta, {
      getSaveAge() {
        if (this.saved) {
          return moment(this.saved).fromNow();
        }
        
        return 'Never saved';
      },
      getSavedHuman() {
        return (this.saved) ? moment(this.saved).format('l @ LT') : 'Never';
      }
    });
  },
  legacySaveSlots(state) {
    if (state.saveSlots && Object.keys(state.saveSlots).length > 0) {
      return state.saveSlots;
    }
        
    return null;
  },
  saveSlots(state, getters) {        
    return get(getters, ['backend/storage/saveSlots'], {});
  },
  loading(state) {
    return state.loading;
  },
}