import md5 from 'md5';
import { omit } from 'lodash';

const promptDefaults = {
  title: 'Notice',
  type: 'is-danger'
}

const confirmDefaults = {
  title: 'Notice',
  type: 'is-danger'
}

const alertDefaults = {
  title: 'Alert',
  type: 'is-success'  
}

const snackbarDefaults = {
  duration: 4000,
  position: 'is-bottom',
  actionText: null
}
                  
export default {
  namespaced: true,
  state: {
    0: [],  
    1: [],
    2: [],
    active: null
  },
  mutations: {
    addToQueue(state, { priority, config }) {
      config.priority = priority;
      
      Vue.set(state, priority, [...state[priority], config]);
    },

    removeFromQueue(state, { priority, uuid }) {
      const idx = state[priority].findIndex(c => c.uuid == uuid);
      
      if (idx > -1) {        
        state[priority].splice(idx, 1);        
      }
    },

    setActive(state, { active }) {
      state.active = active;
    },
    resetState(state) {
      state = {
        0: [],
        1: [],
        2: [],
        active: null
      }
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    setActive({ commit }, { active }) {
      commit('setActive', { active });
    },
    queueDialog({ commit, state, getters }, { priority, config }) {      
      config.uuid = md5(JSON.stringify(omit(config, ['onConfirm','onCancel'])));      
      console.log('queued', config, priority);
      if (getters.active && getters.active.uuid == config.uuid) {
        return;
      }

      if (state.hasOwnProperty(priority) && !state[priority].find(c => c.uuid == config.uuid)) {        
        for (let [fnKey, fn] of Object.entries({ onConfirm: config.onConfirm, onCancel: config.onCancel})) {          
          config[fnKey] = function (...args) {
            const _this = this;                   
            commit('removeFromQueue', { priority, uuid: config.uuid });
            commit('setActive', { active: null });
            if (fn) fn.call(_this, ...args);
          }
        }        
      }

      commit('addToQueue', { priority, config })
    },
    alert({ dispatch }, { priority, message, config = {}}) {
      const mergedConfig = { ...alertDefaults, ...config, message, __type: 'alert' };
      dispatch('queueDialog', { priority, config: mergedConfig });      
    },
    
    confirm({ dispatch }, { priority, message, config }) {
      const mergedConfig = { ...confirmDefaults, message, ...config, __type: 'confirm' };
      dispatch('queueDialog', { priority, config: mergedConfig });
    },

    prompt({ dispatch }, { priority = 0, config }) {
      const mergedConfig = { ...promptDefaults, ...config, __type: 'prompt'};
      dispatch('queueDialog', { priority, config: mergedConfig });
    },

    snackbar({ dispatch }, { priority = 0, message, config = {}}) {
      const mergedConfig = { ...snackbarDefaults, ...config, __type: 'snackbar', message };
      dispatch('queueDialog', { priority, config: mergedConfig });
    }
  },
  getters: {
    queue(state) {      
      return [state[2], state[1], state[0]].reduce((accum, queue) => {
        if (queue.length && !accum) {
          accum = queue[0];
          return accum;
        }

        return accum;
      }, null);
    },
    active(state) {
      return state.active;
    }  
  }
}