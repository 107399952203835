/* constants.js */

export const NEW_AREA = function({ 
  type='area',
  shape='rectangle',
  paths=null,
  selectedHandleIdx=null,
  mode=null
}) {    
  this.type = type;
  this.shape = shape;
  this.paths = paths;
  this.mode = mode;
  this.displayIndex = 0;
  this.selectedHandleIdx = selectedHandleIdx;
}

export const snackbarDefaults = {
  duration: 3000,
  position: 'is-bottom',
  actionText: null
}

export const shapeDefaults = {
  type: 'area',
  distanceVisible: false,
  segmentLengths: [],
  labelText: '',
  fillColor: '',
  displayIndex: 0
}

export const startEndHandleZoomFormulas = {
  21: (z) => (.1 * z) - 1.1,
  20: (z) => (.1 * z) - 1,
  19: (z) => (.1 * z) - .9,
  18: (z) => (.1 * z) - .8,
  17: (z) => (.1 * z) - .7,
  16: (z) => (.1 * z) - .6,
  15: (z) => (.1 * z) - .5,
  14: (z) => (.1 * z) - .4,
  13: (z) => (.1 * z) - .3,
  12: (z) => (.1 * z) - .2,
  11: (z) => (.1 * z) - .1,
  10: (z) => (.1 * z),
  9: (z) => (.1 * z) + .1,
  8: (z) => (.1 * z) + .2,
  7: (z) => (.1 * z) + .3,
  6: (z) => (.1 * z) + .4,
  5: (z) => (.1 * z) + .5,
  4: (z) => (.1 * z) + .6,
  3: (z) => (.1 * z) + .7,
  2: (z) => (.1 * z) + .8,
  1: (z) => (.1 * z) + .9  
}

export const midPointZoomFormulas = {
  21: (z) => (.7 * z) - 11.3,
  20: (z) => (.7 * z) - 10.87,
  19: (z) => (.7 * z) - 10.44,
  18: (z) => (.7 * z) - 10.01,
  17: (z) => (.7 * z) - 9.15,
  16: (z) => (.7 * z) - 8.72,
  15: (z) => (.7 * z) - 8.29,
  14: (z) => (.7 * z) - 7.86,
  13: (z) => (.7 * z) - 7.43,
  12: (z) => (.7 * z) - 7.09,
  11: (z) => (.7 * z) - 5.3,
  10: (z) => (.7 * z) - 4,
  9: (z) => (.7 * z) - 3.5,
  8: (z) => (.7 * z) - 3,
  7: (z) => (.7 * z) - 2,
  6: (z) => (.7 * z) - 1,
  5: (z) => (.7 * z),
  4: (z) => (.7 * z) + 1,
  3: (z) => (.7 * z) + 2,
  2: (z) => (.7 * z) + 3,
  1: (z) => (.7 * z) + 3.5,
}