import { get as _get } from 'lodash'

function DraggableBehavior() {  
  this.events = {    
    'mousedown':'dragstart',
    'touchstart':'dragstart',
    'touchmove':'dragmove',
    'touchend':'dragstop',
    'mouseup':'dragstop',
    'mousemove':'dragmove'
  }
}

DraggableBehavior.prototype.dragstart = function(btn, e) {
  // start the move, set up the origin
  // console.log('drag start');
  this.set('origin', e);
  this.getMap().set('draggable', false);  
  this.getMap().set('_noclicks', true);

  return false;
}

DraggableBehavior.prototype.dragmove = function(btn, e) {  
  let origin = this.get('origin');  

  if (origin) {
    // console.log('drag move');
    // if the drag has already started
    e.preventDefault();
    e.stopPropagation();

    
    // get the cursor/touch event x/y and convert to latlang coords on the map
    let left = _get(origin, 'targetTouches[0].clientX', origin.clientX) - _get(e, 'targetTouches[0].clientX', e.clientX),
        top = _get(origin, 'targetTouches[0].clientY', origin.clientY) - _get(e, 'targetTouches[0].clientY', e.clientY),
        pos = this.getProjection()
          .fromLatLngToDivPixel(this.get('position')),
        latLng = this.getProjection()
          .fromDivPixelToLatLng(new google.maps.Point(pos.x - left, pos.y - top));

    
    // reset the current origin to the current position
    this.set('origin', e);
    // update the overlay position
    this.set('position', latLng);
    // console.log('position: ' + this.get('position'));
    this.draw();
    
    return latLng;    
  }

  return false;
}

DraggableBehavior.prototype.dragstop = function(btn, e) {    
  // console.log('drag stop');
  this.getMap().set('_noclicks', false);
  
  if (this.get('origin')) {
    this.getMap().set('draggable', true);
    this.set('origin', null);  

    return this.get('position');  
  }

  return false;
}

DraggableBehavior.prototype.setup = function(btn) {  
  let fn = (function (e) {
    if (this.get('origin')) {
      this._cmpInstance.$emit('overlay-event', e.type, btn, e);
    }
  }).bind(this);

  this.getMap().getDiv().addEventListener('touchmove', fn);
  this.getMap().getDiv().addEventListener('mousemove', fn);

  this.getMap().getDiv().addEventListener('touchend', fn);
  this.getMap().getDiv().addEventListener('mouseup', fn);
}

DraggableBehavior.prototype.handleEvent = function(eventName, e) {
  if (this.events[eventName]) {
    return this.events[eventName];    
  }
}

export default DraggableBehavior;