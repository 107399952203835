import { cloneDeep, get } from 'lodash'

export default function StyleManager(styles) {
  const NORMAL_STATE = 'normal';
  const _styles = styles;

  return {
    get(name, state = NORMAL_STATE) {
      return cloneDeep(get(_styles, `${state}.${name}`, NORMAL_STATE));
    }
  }
}