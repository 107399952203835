/* MapShape.js */
import Dispatcher from '../events.js'
import store from '@/store/index.js'

import ControlOverlay from './ControlOverlay.js'
import PathSegmentMeasurementOverlay from './PathSegmentMeasurementOverlay.js'
import ShapeLabelOverlay from './ShapeLabelOverlay.js'
import polylabel from 'polylabel'

import ClickableBehavior from './behaviors/ClickableBehavior';
import DraggableBehavior from './behaviors/DraggableBehavior';

import geolib from 'geolib'
import { cloneDeep, get, takeWhile, isFunction, debounce } from 'lodash'

function MapShape(map, {
  indexID=new Date().getTime(),
  labelText='',
  displayIndex=1,
  highlighted=false,
  cutout=false,
  distanceVisible=false,
  paths=null,
  selected=false,
  selectedHandleIdx=null,  
  mode=null,  
  initialZoom=null,
  zoom=null,
  editingEnabled=true,
  styleMgr
} = {}) {
  this.dispatcher = new Dispatcher();
  
  this._labelText = labelText;
  this._displayIndex = displayIndex;
  this._map = map;
  this._mode = mode;
  this._cutout = cutout;  
  this._distanceVisible = distanceVisible;
  
  this._styleMgr = styleMgr;

  this._drawingLine = null;
  this._edgeData = [];
  this._hideDrawingLine = false;
  this._hideHandles = false;
  this._shapeControlOverlay = null;
  this._handleControlOverlay = null;
  this._shapeLabelOverlay = null;

  this._highlighted = highlighted;
  this._selected = selected;
  this._selectedHandleIdx = selectedHandleIdx;  
  
  this._dragTimer = null;

  this._editVertex = null;
  this._markers = new Map();
  this._segmentTextOverlays = [];

  this.indexID = indexID;  
  
  this._lastCenter = this.getCenter();
  this._lastHandleControlOverlayPosition = null;


  // console.log('in constructor, initial zoom:', this._initialZoom);
  this._initialZoom = initialZoom;
  this._zoom = zoom;
  this._editingEnabled = editingEnabled;

  store.dispatch('increaseGlobalZIndex');

  this._initEvents();

  this.updateHandles();  
  this._updateShapeLabel();
  this._updateEdgeCenterMarkers();

  // if (this._cutout) store.dispatch('setAreaCutout', { area: { indexID }, cutout: true });
  this.updateFromState(this.getState(), true);
  if (this._selectedHandleIdx !== null) {
    this._setSelectedHandle(this._selectedHandleIdx);
  }
}

MapShape.prototype.getType = function() {
  return this._type;
}

MapShape.prototype.getLabelText = function() {
  return this._labelText;
}

MapShape.prototype.getDisplayIndex = function() {
  return this._displayIndex;
}

MapShape.prototype.setDisplayIndex = function(index) {
  this._displayIndex = index;
  this._updateShapeLabel();
}

MapShape.prototype.setLabelText = function(labelText) {
  this._labelText = labelText;
  this._updateShapeLabel();
}

MapShape.prototype.setEditingEnabled = function(enabled) {
  this._editingEnabled = enabled;

  if (!enabled) {
    this._setAreaControlVisible(false);
  }
}
MapShape.prototype.getSelectedStatus = function() {
  return this._selected;
}

MapShape.prototype.getHighlightedStatus = function() {
  return this._highlighted;
}

MapShape.prototype.getPathVertexCount = function() {
  return this._poly.getPath().getLength();
}

MapShape.prototype._getAngle = function(latLngFrom, latLngTo) {
  if (!latLngFrom || !latLngTo) return 0;  
  const projection = this._map.overlay.getProjection();
  
  if (!projection) return 0;

  let {x: pixelX1, y: pixelY1} = projection.fromLatLngToContainerPixel(latLngFrom);
  let {x: pixelX2, y: pixelY2} = projection.fromLatLngToContainerPixel(latLngTo);

  return (Math.atan2(pixelY2 - pixelY1, pixelX2 - pixelX1) * 180 / Math.PI - 90);
}

MapShape.prototype._initEvents = function () {
  let poly = this._poly;
  let path = poly.getPath();

  path.addListener('set_at', (idx, prevItem) => {
    let curItem = path.getAt(idx);
    
    if (!poly.getMap() || (curItem.toString() != prevItem.toString())) {
      this._handleLineBoundsChanged.apply(this, path);
    }
  });

  path.addListener('insert_at', this._handleLineBoundsChanged.bind(this, path));
  path.addListener('remove_at', this._handleLineBoundsChanged.bind(this, path));

  poly.addListener('click', this._handleInspectLine.bind(this));

  // custom events are being used for these
  this.dispatcher.on('mouseover', this._handleMouseOver.bind(this));
  this.dispatcher.on('mouseout', this._handleMouseOut.bind(this));
  
  this.dispatcher.on('globalmodechanged', this._handleGlobalModeChange.bind(this));  
  this.dispatcher.on('globalshapeselectedchanged', this._handleGlobalShapeSelectedChange.bind(this));  

  this.dispatcher.on('mapzoomchanged', this._handleMapZoomChange.bind(this));
  this._map.addListener('idle', this._updateHandleRotations.bind(this));
    
  
  poly.setMap(this._map);
}

MapShape.prototype._handleLineBoundsChanged = function(path, event) {
  this.dispatcher.dispatch('boundschanged', this);
  this.dispatcher.dispatch('updated', this);

  if (!this._selected) this.dispatcher.dispatch('shapeselected', this);

  this.updateHandles();  

  debounce(() => {    
    this._resetEdgeCenterMarkers();
    this._updateEdgeCenterMarkers();    
    this._updateAreaControlPosition();   
    this._updateHandleRotations();
    this._updateShapeLabel();
  }, 250, { trailing: true })();

  if (this._selectedHandleIdx !== null && this._mode == 'draw') {
    this._setHandleControlVisible(true, this._selectedHandleIdx);
  }  
},

MapShape.prototype._handleInspectLine = function() {
  this.dispatcher.dispatch('updated', this);
  this.dispatcher.dispatch('shapeselected', this);
},

MapShape.prototype._handleMouseOver = function(event)
{
  // console.log('mouse over');
  // this._setEdgeCentersVisible(true);
  // this._setDrawingLinesVisible(false);
  // this._setAreaControlVisible(true);
}

MapShape.prototype._handleMouseOut = function(event)
{
  // console.log('mouse out');
  // this._setEdgeCentersVisible(false);
  // this._setDrawingLinesVisible(true);
}

MapShape.prototype._handleMarkerDrag = function(pathIdx, event)
{
  this._poly.getPath().setAt(pathIdx, event.latLng);

  // if (pathIdx != this._selectedHandleIdx) {
  //   this._setSelectedHandle(null);
  //   return;
  // }

  this._setSelectedHandle(pathIdx);
}

MapShape.prototype._handleMarkerClick = function(pathIdx, event)
{  
  if (this._mode == 'draw') {
    if (pathIdx == this._selectedHandleIdx) {
      this._setSelectedHandle(null);
      return;
    }

    this._setSelectedHandle(pathIdx);
  } else if (store.state.mode == 'move') {
    store.dispatch('setLastMapClickEvent', { event, delayedClear: true });
    store.dispatch('setCurrentShape', this);
  }  
}

MapShape.prototype._handleMarkerMouseOver = function(pathIdx)
{  
  if (pathIdx == this._selectedHandleIdx) {
    // this._setHandleControlVisible(true, pathIdx);
    return;
  }  
}

// MapShape.prototype._handleMarkerMouseOut = function(pathIdx)
// {
//   this._setHandleControlVisible(pathIdx, false);
// }

MapShape.prototype._handleShapeControlOverlayDrag = function() {
  if (this._selected) {
    this._lastCenter = this._shapeControlOverlaylay.get('position');
    // this._updateShadowPoly();  
  }
}

MapShape.prototype._handleShapeControlOverlayDragEnd = function(latLng) {
  if (latLng && this._selected) {    
    let targetLatLng = latLng;
    let oldPath = this._poly.getPath();

    let projection = this._map.overlay.getProjection();

    let {x: oldX, y: oldY} = projection.fromLatLngToContainerPixel(this._lastCenter);
    let {x: newX, y: newY} = projection.fromLatLngToContainerPixel(targetLatLng);
    // console.log(targetLatLng.toString());
    let diffX = newX - oldX;
    let diffY = newY - oldY;

    oldPath.forEach((pathLatLng, idx) => {
      let {x: pathX, y: pathY} = projection.fromLatLngToContainerPixel(pathLatLng);
      let tempPoint = new google.maps.Point(pathX + diffX, pathY + diffY);

      oldPath.setAt(idx, projection.fromContainerPixelToLatLng(tempPoint));
    });
        
    this._lastCenter = targetLatLng;
  }
}

MapShape.prototype._handleMapZoomChange = function(newZoom) {  
  // console.log('inhandlemapzoomchange');
  this._zoom = newZoom;  
  this.updateHandles();
}

MapShape.prototype._handleGlobalModeChange = function(mode) {
  console.log('in handle mode changed', mode)
  this._setShapeLabelVisible(false);  
}

MapShape.prototype._handleGlobalShapeSelectedChange = function(shape) {
  console.log('in global shape selected change', shape)
  let show = ((shape && shape.indexID == this.indexID) || !shape) ? true : false;
  this._updateShapeLabel();
  this._setShapeLabelVisible(show);
}


MapShape.prototype.handleParentMapMouseOut = function(event)
{
  // this._setDrawingLinesVisible(false);
}

MapShape.prototype.handleParentMapMouseOver = function(event)
{
  // if (this._mode == 'draw') this._setDrawingLinesVisible(true);
}

/**
 * handleMapMouseMove
 *
 * Receive parent map mouse move events
 */
MapShape.prototype.handleParentMapMouseMove = function(event)
{
  // this._checkMouseOver(event.latLng);
  // this._updateDrawingLines(event.latLng);
}

MapShape.prototype._removeVertex = function(vertex)
{
  this._deletePathSegmentText();

  let path = this._poly.getPath();
  let previousVertex = null;

  path.removeAt(vertex);  
  this.updateHandles();

  if (path.getLength() > 0) {
    previousVertex = (vertex == 0) ? path.getLength() - 1 : vertex - 1;
  }  

  if (path.getLength() == 0) {
    store.dispatch('deleteShape', { shape: this });
    return;
  }

  this._setSelectedHandle(previousVertex);  
},

MapShape.prototype._handleEdgeCenterMouseOver = function(marker, edge) {
  marker.setOptions(this._styleMgr.get('centerMarkers','highlighted'));
  // this._setDrawingLinesVisible(false);
}

MapShape.prototype._handleEdgeCenterMouseOut = function(marker, edge) {
  // this._setDrawingLinesVisible(true);
  marker.setOptions(this._styleMgr.get('centerMarkers'));
}

MapShape.prototype._handleEdgeCenterClick = function(marker, edge) {
  this._editVertex = edge.pairIdx[0];
  this.addCoordinate(marker.getPosition());

  this._setSelectedHandle(this._editVertex);  
}

MapShape.prototype._handleHandleControlDrag = function(latLng) {
  if (this._selectedHandleIdx !== null) this._poly.getPath().setAt(this._selectedHandleIdx, latLng);
}

MapShape.prototype._handleHandleControlAddClick = function() {
  if (this._selectedHandleIdx !== null) {
    this._setHandleControlVisible(false);
    store.dispatch('setMode', 'draw');
  }
}

MapShape.prototype._handleHandleControlTrashClick = function() {
  this._removeVertex(this._selectedHandleIdx);  
}

MapShape.prototype._resetEdgeCenterMarkers = function() {  
  this._edgeData.forEach((e) => {
    e.marker.setMap(null);
    delete e.marker;
  });

  this._edgeData = [];  
}

/**
 * _checkMouseOver
 *
 * Since our poly is not dispatching normal mouseover events, we
 * need to check them on our own and fire them.
 */
MapShape.prototype._checkMouseOver = function(latLng)
{
  if (google.maps.geometry.poly.containsLocation(latLng, this._poly)) {
    this.dispatcher.dispatch('mouseover', this._handleMouseOver.bind(this));
    return;
  }

  this.dispatcher.dispatch('mouseout', this._handleMouseOut.bind(this));
}

MapShape.prototype._updateEdgeCenterMarkers = function() {
  let path = this._poly.getPath();
  const pairs = this.getSegmentPairs();

  pairs.forEach((pair, idx) => {
    let {latitude: lat, longitude: lng} = geolib.getCenter([
      { latitude: pair[0].lat(), longitude: pair[0].lng() },
      { latitude: pair[1].lat(), longitude: pair[1].lng() }
    ]);

    let pairCenter = new google.maps.LatLng(lat, lng);
    let pairLength = google.maps.geometry.spherical.computeLength(pair);

    let edge = this._edgeData[idx];

    if (!edge) {
      let marker = new google.maps.Marker({
        visible: false,
        map: this._map,
        draggable: false
      });

      marker.setOptions(this._styleMgr.get('centerMarkers'));

      edge = {
        pair: pair,
        pairIdx: [idx, idx + 1],
        marker: marker,
        tooShort: false
      }

      // marker.addListener('mouseover', this._handleEdgeCenterMouseOver.bind(this, marker, edge));
      marker.addListener('mouseout', this._handleEdgeCenterMouseOut.bind(this, marker, edge));
      marker.addListener('click', this._handleEdgeCenterClick.bind(this, marker, edge));
    }

    edge['tooShort'] = pairLength < 2;
    edge.marker.setPosition(pairCenter);    

    this._edgeData[idx] = edge;
    this._updatePathSegmentText(idx, pairCenter);
  });

  if (this._mode == 'draw') this._setEdgeCentersVisible(true);
}

MapShape.prototype.getCutOutStatus = function () {
  return this._cutout;
}

MapShape.prototype.setCutOut = function (cutout) {
  this._cutout = (cutout !== false) ? true : false;

  this._refreshPolyOptions();  
  this.dispatcher.dispatch('updated', this);
}

MapShape.prototype.setMapZoom = function(zoomNumber) {  
  // console.log('setmapzoom number', zoomNumber);
  this.dispatcher.dispatch('mapzoomchanged', zoomNumber);
}

MapShape.prototype._setEdgeCentersVisible = function(visible)
{
  // console.log('edge centers visible: ' + visible);
  let hide = ((visible === null || visible) && this._selected) ? false : true;
  this._edgeData.forEach((data) => {
    data.marker.setVisible(this._mode == 'draw' && !data.tooShort && !hide);

    if (hide) {
      data.marker.setOptions(this._styleMgr.get('centerMarkers'));
    }
  });
}

MapShape.prototype._updateAreaControlPosition = function()
{ 
  let overlayPosition = this._shapeControlOverlay.get('position');
  let position = overlayPosition || this._lastCenter;

  // only update if the overlay doesn't yet have a position or if the lat or lng are different
  if (overlayPosition && (position.lat() != overlayPosition.lat() || position.lng() != overlayPosition.lng()) || !overlayPosition) {    
    this._shapeControlOverlay.setPosition(position);
  }
}

MapShape.prototype._setAreaControlVisible = function(visible)
{
  if (this._editingEnabled === false) return false;

  let hide = ((visible === null || visible) && this._selected) ? false : true;
  let shapeControlOverlay = this._shapeControlOverlay;

  // did the marker control overlay recently close (it would have set this._lastHandleControlOverlayPosition).
  // If it's set, use that as the new area control overlay.
  // Otherwise...
  // set the control's new latlng to the map's last click latlng if it exists, otherwise set to center of polygon
  // also, only set if we're set the control visible
  let latLng;

  if (this._lastHandleControlOverlayPosition) {
    latLng = this._lastHandleControlOverlayPosition;
    this._lastHandleControlOverlayPosition = null;
  } else {
    latLng = (store.getters.lastMapClickLatLng && !hide) ? store.getters.lastMapClickLatLng : this.getCenter();    
  }

  if (!shapeControlOverlay) {
    shapeControlOverlay = new ControlOverlay(this._map, {      
      options: {
        distance: 30,
        prefix: 'areacontrol'        
      },
      buttons: [{
        icon: 'vector-polyline-edit-icon',
        title: 'Edit layer',
        name: 'edit',
        behavior: new ClickableBehavior(),
        tooltipPlacement: 'top'
      },{
        icon: 'delete-icon',
        title: 'Delete layer',
        name: 'delete',
        behavior: new ClickableBehavior(),
        tooltipPlacement: 'right'
      },{
        icon: 'cursor-move-icon',
        title: 'Drag & release to move',
        name: 'move',
        behavior: new DraggableBehavior(),
        tooltipPlacement: 'bottom'
      },{
        icon: 'check-icon',
        title: 'Done when this layer',
        name: 'done',
        behavior: new ClickableBehavior(),
        tooltipPlacement: 'left'
      }]          
    });
    
    shapeControlOverlay.dispatcher.on('move-dragstart', this._handleShapeControlOverlayDrag.bind(this));
    shapeControlOverlay.dispatcher.on('move-dragstop', this._handleShapeControlOverlayDragEnd.bind(this));
    shapeControlOverlay.dispatcher.on('edit-click', () => store.dispatch('setMode', 'draw'));
    shapeControlOverlay.dispatcher.on('done-click', () => store.dispatch('setCurrentShape', null));
    shapeControlOverlay.dispatcher.on('delete-click', () => store.dispatch('deleteShape', { shape: this }));
    
    this._shapeControlOverlay = shapeControlOverlay;    
  }
  
  if(!hide) shapeControlOverlay.setPosition(latLng);
  shapeControlOverlay.setVisible(!hide); 
    
  this._lastCenter = latLng;
}

MapShape.prototype._setShapeLabelVisible = function(visible) {
  let hide = (visible === null || visible) ? false : true;
  let shapeLabelOverlay = this._shapeLabelOverlay;

  if (!shapeLabelOverlay) {
    return;
  }
  
  shapeLabelOverlay.setVisible(!hide); 
}

MapShape.prototype._updatePathFromArray = function(latLngs) {
  let path = this._poly.getPath();
  
  if (latLngs.length !=  path.getLength()) {
    path.clear();
  }

  latLngs.forEach((latLng, idx) => {
    path.setAt(idx, new google.maps.LatLng(latLng));
  });
}

MapShape.prototype._getHandleStyle = function(idx, { path, forceDeselect=false }={}) {  
  let state = 'normal';
  let entity = 'handles';

  if (this._selectedHandleIdx == idx && !forceDeselect) {
    state = 'selected';
  }  

  const start = (idx == 0);
  const end = (idx == this.getPathVertexCount() - 1);  

  if (start || end) {
    entity = (start) ? 'startHandles' : 'endHandles';
  }

  let style = this._styleMgr.get(entity, state);
  
  if (style.icon.path && this._customGetHandleRotation) {
    let rotation = this._customGetHandleRotation(idx, { start, end });
    if (rotation) {
      style.icon.rotation = rotation;
    }
  }

  const zoom = this._getHandleZoom({ start, end });
  // console.log('gethandlezoom', zoom);
  if (zoom) {
    style.icon.scale = zoom;
  }

  const anchor = this._getHandleAnchor({ start, end });
  if (anchor) {
    style.icon.anchor = anchor;
  }
  
  return style;
}

MapShape.prototype._getHandleZoom = function() {}
MapShape.prototype._getHandleAnchor = function() {}

MapShape.prototype.updateHandles = function(newPath)
{
  let path = newPath || this._poly.getPath();

  if (path.length != this._markers.size) {
    this._markers.forEach((m) => {
      m.setMap(null);
    });

    this._markers.clear();
  }

  path.forEach((latLng, idx) => {
    let marker = this._markers.get(idx);

    if (!marker) {
      let style = this._getHandleStyle(idx);
      let options = cloneDeep(style);
      
      options.title = `marker_${this.indexID}_${idx}`;
      options.visible = false;
      
      marker = new google.maps.Marker(options);
      marker.setMap(this._map);

      marker.addListener('drag', this._handleMarkerDrag.bind(this, idx));
      // marker.addListener('rightclick', this._handleMarkerRightClick.bind(this, idx));
      marker.addListener('click', this._handleMarkerClick.bind(this, idx));
      marker.addListener('mouseover', this._handleMarkerMouseOver.bind(this, idx));

      this._markers.set(idx, marker);
    }

    marker.setPosition(latLng);  
    marker.setZIndex(store.state.globalZIndex);          

    if (isFunction(this._customGetHandleVisibility)) {
      marker.setVisible(this._customGetHandleVisibility(idx));
    }    
  }); 
  
  this._updateHandleRotations(path);
}

MapShape.prototype._updateHandleRotations = function(path) {
  if (this._poly) {
    (path || this._poly.getPath() || []).forEach((latLng, idx) => {
      let marker = this._markers.get(idx);
      
      const style = this._getHandleStyle(idx, { path });
      if (style.icon.path) {
        marker.setIcon(style.icon);
      }
    });
  }
}

MapShape.prototype.setDistanceVisible = function(visible)
{
  console.log('set distance visible');
  this._distanceVisible = visible;
  this._updateEdgeCenterMarkers(); 
  this.updateHandles();
}

MapShape.prototype._updatePathSegmentText = function(idx, latLng) {  
  let path = this._poly.getPath();
  
  
  let textOverlay = this._segmentTextOverlays[idx];
  
  if (!textOverlay && this._distanceVisible) {
    textOverlay = new PathSegmentMeasurementOverlay(this._map, {      
      classes: [
        'path-segment-text'
      ],
      options: {        
        shapeIndexID: this.indexID,
        segmentIdx: idx,
        prefix: 'segmenttext'      
      }
    });
  }
  
  if (textOverlay) {
    textOverlay.setPosition(latLng);
    textOverlay.setVisible(this._distanceVisible);  

    this._segmentTextOverlays[idx] = textOverlay; 
  }
}

MapShape.prototype._updateShapeLabel = function() {
  return _.debounce(() => {
    let textOverlay = this._shapeLabelOverlay;
    
    if (!textOverlay) {
      textOverlay = new ShapeLabelOverlay(this._map, {      
        options: {        
          prefix: 'shapelabel'
        },
        classes: ['shape-label']      
      });
    }
    
    if (textOverlay) {
      const pole = this.getPoleOfInaccessibility();
      textOverlay.setPosition(pole);

      let hide = false;
      let pathSegmentCount = this._poly.getPath().getLength();
      if (pathSegmentCount < 2) {
        hide = true;
      }

      if (store.state.mode !== 'move') hide = true;

      textOverlay.setVisible(!hide);  
      textOverlay.$setProp('text', this._displayIndex);

      this._shapeLabelOverlay = textOverlay; 
    }
  }, 250, { trailing: true })();
}

MapShape.prototype._deletePathSegmentText = function() {  
  this._segmentTextOverlays.forEach((overlay, idx) => {    
    overlay.setMap(null);
    delete this._segmentTextOverlays[idx];
  });
}

MapShape.prototype._setHandleControlVisible = function(visible, idx)
{ 
  let hide = ((visible === null || visible) && this._selected) ? false : true;

  let controlOverlay = this._handleControlOverlay;
  let parentMarker = this._markers.get(idx);

  if (idx !== null && parentMarker) {
    let latLng = parentMarker.getPosition();

    if (!controlOverlay) {
      controlOverlay = new ControlOverlay(this._map, {
        options: {
          prefix: 'handlecontrol',
          distance: 83
        },
        buttons: [{
          icon: 'delete-icon',
          title: 'Delete this point',
          name: 'delete',
          behavior: new ClickableBehavior(),
          tooltipPlacement: 'top'
        },
        {
          icon: 'cursor-move-icon',
          title: 'Drag to move this point',
          name: 'move',
          behavior: new DraggableBehavior(),
          tooltipPlacement: 'bottom'
        },
        {
          icon: 'check-icon',
          title: 'Done changing points',
          name: 'done',
          behavior: new ClickableBehavior(),
          tooltipPlacement: 'bottom'
        }        
      ]
      });
      controlOverlay.dispatcher.on('move-dragmove', this._handleHandleControlDrag.bind(this));
      controlOverlay.dispatcher.on('delete-click', this._handleHandleControlTrashClick.bind(this));
      controlOverlay.dispatcher.on('done-click', () => {
        this._lastHandleControlOverlayPosition = this._handleControlOverlay.get('position');
        store.dispatch('setMode', 'move')
      });
      
      this._handleControlOverlay = controlOverlay;
    }

    controlOverlay.setPosition(latLng);
  }

  if (controlOverlay) {
    // console.log('set control overlay visible: ' + (!hide));
    controlOverlay.setVisible(!hide);
  }
}

MapShape.prototype._setSelectedHandle = function(idx)
{  
  if (idx == null) {
    // unhighlight all markers    
    this._markers.forEach((marker, idx) => {      
      marker.setOptions(this._getHandleStyle(idx));
    });

    // hide controls
    this._setHandleControlVisible(false);
    this._selectedHandleIdx = null;
    this._editVertex = null;

    this.dispatcher.dispatch('handledeselected');
    return;
  }

  if (this._selectedHandleIdx !== null) {
    // unhighlight old marker
    let previousHandle = this._markers.get(this._selectedHandleIdx);
    if (previousHandle) {      
      previousHandle.setOptions(this._getHandleStyle(this._selectedHandleIdx, { forceDeselect: true }));
      this._setHandleControlVisible(false, this._selectedHandleIdx);
    }
  }

  // highlight new marker, regardless of mode
  this._selectedHandleIdx = idx;
  this._editVertex = idx;

  let marker = this._markers.get(idx);
  marker.setOptions(this._getHandleStyle(idx));

  if (this._mode == 'draw' || this._mode == 'free') {
    // if mode is move, show the controls
    this._setHandleControlVisible(true, idx);
  }

  if (this._mode == 'move') {
    // ... and hide the handle controls
    this._setHandleControlVisible(false);
  }
  
  this.dispatcher.dispatch('handleselected', idx);
}

MapShape.prototype._updateDrawingLines = function(latLng)
{
  if (this._selected) {
    let line = this._drawingLine;

    if (!line) {
      line = new google.maps.Polyline({clickable:false, strokeColor: 'white'});;
      line.setMap(this._map);

      this._drawingLine = line;
    }

    let path = this._poly.getPath();
    if (this._selectedHandleIdx === null) {
      let distances = [];

      path.forEach((handle, idx) => {
        let d = google.maps.geometry.spherical.computeDistanceBetween(handle, latLng);        
        distances.push([d, handle, idx]);
      });

      distances = distances
        .sort((a, b) => a[0] - b[0])
        .slice(0, 2);

      line.setPath([distances[0][1], latLng]);
      this._editVertex = distances[0][2];
    } else {
      this._editVertex = this._selectedHandleIdx;
      line.setPath([path.getAt(this._editVertex), latLng]);
    }

    this._drawingLine.setVisible(!this._hideDrawingLine);
  }
}

MapShape.prototype._setDrawingLinesVisible = function(visible)
{
  let hide = ((visible === null || visible) && this._selected && this._mode == 'draw') ? false : true;
  this._hideDrawingLine = hide;

  if (this._drawingLine) {
    this._drawingLine.setVisible(!hide);
    this._editVertex = null;
  }
}

MapShape.prototype._setShadowPolyVisible = function (visible) {
  // let hide = ((visible === null || visible) && this._selected) ? false : true;

  // let controlOverlay = this._handleControlOverlay;
  // let parentMarker = this._markers.get(idx);

  // if (idx !== null && parentMarker) {
  //   let latLng = parentMarker.getPosition();

  //   if (!controlOverlay) {
  //     controlOverlay = new ControlsOverlay(this._map);
  //     controlOverlay.dispatcher.on('drag', this._handleHandleControlDrag.bind(this));
  //     controlOverlay.dispatcher.on('add-click', this._handleHandleControlAddClick.bind(this));
  //     controlOverlay.dispatcher.on('trash-click', this._handleHandleControlTrashClick.bind(this));

  //     this._handleControlOverlay = controlOverlay;
  //   }

  //   controlOverlay.setPosition(latLng);
  // }

  // if (controlOverlay) {
  //   console.log('set control overlay visible: ' + (!hide));
  //   controlOverlay.setVisible(!hide);
  // }
  // this._shadowPoly = new google.maps.Polygon({
  //   paths: this._poly.getPaths(),
  //   strokeColor: '#000000',
  //   strokeOpacity: 0.8,
  //   fillOpacity: 1,
  //   // draggable: true,
  //   clickable: false,
  //   editable: false,
  //   marker: null,
  //   geodesic: true
  // });

  // this._shadowPoly.setMap(this._map);
}

MapShape.prototype.setGlobalMode = function (mode) {
  this._globalMode = mode;

  this.dispatcher.dispatch('globalmodechanged', mode);
}

MapShape.prototype.setGlobalSelectedShape = function(shape) {
  this._globalSelectedShape = shape;

  this.dispatcher.dispatch('globalshapeselectedchanged', shape);
}

MapShape.prototype.setMode = function (mode) {
  let oldMode = this._mode;
  this._mode = mode;

  switch (this._mode) {    
    case 'free':
      this.updateHandles();
      this._setShapeLabelVisible(false);
      break;

    case 'draw':
      this.updateHandles();
      this._setEdgeCentersVisible(true);
      // this._setDrawingLinesVisible(true);
      this._setAreaControlVisible(false);
      this._setHandleControlVisible(true, 0);
      this._setShapeLabelVisible(false);
      this._setSelectedHandle(0);
      this._poly.setDraggable(false);
      
      break;

    case 'move':
      this.updateHandles();
      this._setEdgeCentersVisible(false);
      // this._setDrawingLinesVisible(false);
      this._setAreaControlVisible(true);
      this._setShapeLabelVisible(true);

      // this._setHandleControlVisible(false);
      
      this._poly.setDraggable(true);
      this._setSelectedHandle(null);
  }

  this.dispatcher.dispatch('modechanged', mode);
}

MapShape.prototype.addCoordinate = function(latLng)
{
  if (this._mode == 'draw') {
    let path = this._poly.getPath();
    
    if (this._editVertex !== null) {
      let insertAt = this._editVertex + 1;
      // console.log('Inserting at: ' + insertAt);
      path.insertAt(insertAt, latLng);

      this._setSelectedHandle(insertAt);
      this.dispatcher.dispatch('coordinateadded', path.getLength());
    }

    this.updateHandles();
  }
}

MapShape.prototype.getSegmentPairs = function() {
  let path = this._poly.getPath();
  const pairs = [];

  path.forEach((pathLatLng, idx) => {
    let pair = [pathLatLng, path.getAt(idx + 1)];
    if (pair[1] === undefined) {
      if (this._type == 'distance') {
        return;
      }

      pair[1] = path.getAt(0);
    }
    
    pairs.push(pair);
  });

  return pairs;
}

MapShape.prototype.getSegmentLengths = function() {  
  return this.getSegmentPairs().map(pair => google.maps.geometry.spherical.computeLength(pair));
}

MapShape.prototype.getPoly = function()
{
  return this._poly;
}

MapShape.prototype.getCenter = function()
{
  return this.getBounds().getCenter();
}

MapShape.prototype.getPoleOfInaccessibility = function(precision) {
  let poly = this._poly;
  let path = poly.getPath();
  let projection = this._map.getProjection();

  if (!projection) return;

  let coordinates = [];
  path.forEach(function(point) {
    let pixelPoint = projection.fromLatLngToPoint(point);
    coordinates.push([pixelPoint.x, pixelPoint.y]);
  });

  // The polylabel library expects polygons in the format: [[[x1, y1], [x2, y2], ...]]
  let formattedPolygon = [coordinates];

  // Get other polygons within the current polygon
  let otherShapes = store.state.shapes;
  let innerPolygon = []; // Move this outside the loop
  for (let i = 0; i < otherShapes.length; i++) {
    let otherShape = otherShapes[i];
    if (otherShape.indexID == this.indexID) continue;

    if (otherShape.paths && otherShape.paths.length > 2) {
      for (let j = 0; j < otherShape.paths.length; j++) {
        let otherPath = otherShape.paths[j];
        let otherPoint = new google.maps.LatLng(otherPath.lat, otherPath.lng);
        // Check if the point is within the current polygon
        if (google.maps.geometry.poly.containsLocation(otherPoint, poly)) {
          let pixelPoint = projection.fromLatLngToPoint(otherPoint);
          innerPolygon.push([pixelPoint.x, pixelPoint.y]);
        }
      }
    }
  }

  // Add the innerPolygon to the formattedPolygon
  if (innerPolygon.length > 0) {
    formattedPolygon.push(innerPolygon);
  }

  // Call polylabel
  let pole = polylabel(formattedPolygon, .0000001);

  // Convert the result back to a Google Maps LatLng object
  let poleLatLng = projection.fromPointToLatLng(new google.maps.Point(pole[0], pole[1]));
  console.log('pole of inaccessibility', poleLatLng.lat(), poleLatLng.lng());
  return poleLatLng;
};

MapShape.prototype.getBounds = function() {
  let bounds = new google.maps.LatLngBounds();
  let path = this._poly.getPath();

  path.forEach(function(latLng) {
    bounds.extend(latLng);
  });

  return bounds;
}

MapShape.prototype.delete = function()
{
  this._edgeData.forEach((e) => {
    e.marker.setMap(null);
    delete e.marker;
  });

  this._markers.forEach((marker, key) => {
    marker.setMap(null);
    this._markers.delete(key);
  });

  // drawing line may have never been rendered, so we have to check for its existence
  if (this._drawingLine) {
    this._drawingLine.setMap(null);
    delete this._drawingLine;
  }

  // if (this._areaControlMarker) {
  //   this._areaControlMarker.setMap(null);
  //   delete this._areaControlMarker;
  // }

  if (this._handleControlMarker) {
    this._handleControlMarker.setMap(null);
    delete this._handleControlMarker;
  }

  if (this._handleControlOverlay) {
    this._setHandleControlVisible(false);
  }

  if (this._shapeControlOverlay) {
    this._setAreaControlVisible(false);
    delete this._shapeControlOverlay;
  }

  if (this._shapeLabelOverlay) {
    this._shapeLabelOverlay.setMap(null);
    delete this._shapeLabelOverlay;
  }
  // remove segment measurements
  this._deletePathSegmentText();

  this._poly.setMap(null);
  delete this._poly;
}

MapShape.prototype._refreshPolyOptions = function() {
  let state = 'normal';
  let options;

  if (this._selected) state = 'selected';  
  options = this._styleMgr.get('shape', state);

  if (this._cutout) options = {...options, ...this._styleMgr.get('shape', 'cutout') };
  if (this._highlighted) options = {...options, ...this._styleMgr.get('shape', 'highlighted')};
  
  this._poly.setOptions(options);
}

MapShape.prototype.setHighlighted = function(highlight)
{
  this._highlighted = (highlight !== false);
  this._refreshPolyOptions();
}

MapShape.prototype.setSelected = function(selected)
{
  // if (this._selected && this._handleControlMarker && this._handleControlMarker.getVisible()) {
  //   this._setSelectedHandle(false);
  //   return;
  // }

  store.dispatch('increaseGlobalZIndex');  
  this._poly.setOptions({
    zIndex: store.state.globalZIndex
  });

  this._selected = (selected !== false) ? true : false;
  // this._setDrawingLinesVisible(this._selected);
  this._setAreaControlVisible(this._selected);

  this._refreshPolyOptions();
  this._setSelectedHandle(null);
  this.updateHandles();

  this.dispatcher.dispatch('updated', this);
}

MapShape.prototype.resetOptions = function()
{
  let options = (this._selected) ? this._styleMgr.get('shape','selected') : this._styleMgr.get('shape');
  this._poly.setOptions(options);
}

export {
  MapShape
}
