import { get } from 'lodash'
import Vue from 'vue'

export default {  
  state: {
    lastAction: {},
    timers: {}
  },
  mutations: {
    SET_TOOLTIP_VISIBILITY (state, payload) {
      Vue.set(state, payload.uniqueName, payload.visible);
    },
    SET_LAST_ACTION (state, payload) {      
      state.lastAction = { ...state.lastAction, [payload.uniqueName]: payload.visible }
    },
  },
  actions: {
    setTooltipVisibility ({ state, commit }, payload) {      
      let setVisibility = (function(payload, visible) {        
        commit('SET_TOOLTIP_VISIBILITY', { ...payload, visible }); 
        commit('SET_LAST_ACTION', { ...payload, visible });        
      }).bind(this, payload);

      if (!payload.visible) {               
        setVisibility(false);
        return;
      }
      
      if (payload.visible == true && (get(state, `lastAction.${payload.uniqueName}`, false) == false)) {        
        Vue.nextTick(() => setVisibility(true));

        if (payload.useTimeout) {
          setTimeout(() => setVisibility(false), parseInt(process.env.VUE_APP_DEFAULT_TOOLTIP_TIMEOUT));
        }

        return;
      }
    },  
  },
}